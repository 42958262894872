import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Olá Pessoal, eu sou <span className="purple">Kaio Ribeiro </span>
            de <span className="purple"> Guarapari, Brasil. </span>Tenho 36 anos e sou pai de uma família encantadora, com quatro lindas princesas.
            <br />
            Atualmente, sou freelancer em desenvolvimento de websites e sistemas personalizados para empresas.<br />
            Além das habilidades de programação, possuo formação superior em Ciências Contábeis e Direito, e estou atualmente cursando Engenharia de Software.
            <br />
            <br />
            Além da codificação, algumas outras atividades que eu adoro fazer!</p>
          <ul>
          <li className="about-activity">
              <ImPointRight /> Roblox (LIZOKINHAEPAI)
            </li>
            <li className="about-activity">
              <ImPointRight /> Jogar WoW
            </li>
            <li className="about-activity">
              <ImPointRight /> Treinar
            </li>
            <li className="about-activity">
              <ImPointRight /> Estudar
            </li>
            <li className="about-activity">
              <ImPointRight /> Resolver Enigmas
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            
"Esforce-se para construir coisas que façam diferença!"{" "}
          </p>
          <footer className="blockquote-footer">Soumyajit</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
