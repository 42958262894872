import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Desenvolvimento Web",
          "Sistemas Personalizados",
          "Desing Moderno e Futurista",
          "Aplicações Integradas",
          "Aplicativos Android & IOS",
          "Mentor de boas ideias",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 60,
      }}
    />
  );
}

export default Type;
