import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
             QUEM <span className="purple"> <b>SOU</b></span> EU
            </h1>
            <p className="home-about-body">
            Bem-vindo ao meu espaço digital! Sou <b className="purple">Kaio Ribeiro</b>, um entusiasta e desenvolvedor web apaixonado por explorar os limites da tecnologia para criar experiências online incríveis. 🤷‍♂️
              <br />
              <br /> Com um foco especial em <b className="purple">JavaScript</b>, mergulho no mundo do desenvolvimento para
              
                transformar ideias em<b className="purple">  realidade digital.</b>
              
              <br />
              <br />
              Minha jornada neste vasto universo da programação é marcada por uma &nbsp;
              
                <b className="purple">busca incessante por inovação, </b>
                aprendizado contínuo e {" "}
                <b className="purple">
                aprimoramento das habilidades técnicas.</b>
              
              <br />
              <br />
              Aqui neste espaço, compartilho minha jornada, projetos empolgantes e insights sobre as
               últimas tendências no desenvolvimento web utilizando<b className="purple"> Node.js e React</b>. Prepare-se para uma viagem fascinante pelo 
              
                <b className="purple">
                  {" "}
                   
               mundo do código
                </b>, onde cada linha escrita
             
              é uma oportunidade para criar algo
              
                <b className="purple"> verdadeiramente
                extraordinário.</b>
              
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>ECONTRE-ME</h1>
            <p>
            Sinta-se à vontade para <span className="purple">tirar suas </span>dúvidas
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/soumyajit4419"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/Soumyajit4419"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/soumyajit4419/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/soumyajit4419"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
