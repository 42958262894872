import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import jsguanabara from "../../Assets/Projects/jsguanabara.png";
import phpguanabara from "../../Assets/Projects/phpguanabara.png";
import webdes from "../../Assets/Projects/webdes.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Meus <strong className="purple">Skills </strong>
        </h1>
        <p style={{ color: "white" }}>
          Algumas das minhas formações recentes no mundo da programação
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="React do Zero a Maestria (c/ hooks, router, API, Projetos),"
              description="Aplicações completas com React,
              gerenciamento de páginas com React Router,
              utilização de todos os hooks do React,
              gerenciamento de contexto com Context API,
              integração de React com Firebase,
              aplicações com MERN (MongoDB, Express, React e Node),
              projetos com React e Redux."
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="React Native: Desenvolva APPs Nativas para Android e iOS"
              description="Base sólida de Javascript,
              fundamentos e ciclo de vida do React,
              aplicações estilizadas com Flexbox,
              projetos de aplicações reais com layouts atrativos,
              rotas, eventos de swipe, imagens, câmeras e diversas outras funcionalidades,
              aplicações com Redux,
              desenvolvimento de servidor backend em NodeJs, utilizando banco relacional,
              banco NoSQL e Firebase"
             
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Node.js do Zero a Maestria com diversos Projetos"
              description="Node do básico ao avançado,
              projetos completos com Express,
              padrão MVC com Node,
              criação de API com Node e Express,
              operações e integração de Node JS com MySQL,
              operações e integração de Node JS com Sequelize,
              operações e integração de Node JS com MongoDB e
              operações e integração de Node JS com Mongoose"
                      
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Renda extra com aplicativos: React Native e AdMob"
              description="Conceitos básicos, ambiente de desenvolvimento e criação de um projeto inicial,

              componentes e estilização,
              
              manipulação de estado com o useState do React Native,
              
              visão geral da plataforma do Google e configuração do AdMob,
              
              criação e exibição de anúncios de banner para otimizar a monetização de seus aplicativos,
              
              publicação: orientações e boas práticas para enviar seu aplicativo para as lojas de aplicativos."
              ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Desenvolvimento Web Avançado com PHP, Laravel e Vue.JS"
              description="Rotas, grupos e verbos HTTP Get, Post, Delete, Put e Patch,
              motor de renderização Blade,
              desenvolvimento incremental de bancos de dados relacionais utilizando Migrations,
              Seeders e Factories,
              Tinker,
              manipular e validar formulários,
              interceptar requisições e respostas utilizando Middlewares,
              implementar as operações CRUD utilizando o Eloquent ORM,
              autenticação por Session e Token (JWT),
              padrão de arquitetura MVC (Model, View e Controller),
              exportar arquivos nos formatos XLSX, CSV e PDF,
              construir APIs Webservices REST,
              Bootstrap (CSS) , Laravel , VueJS todos integrados com
              banco de dados Redis"
             
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="React Native na Prática: Apps para iOS e Android [2023]"
              description="React, Hooks, componentes customizados, Redux, requisições, styled-components de forma avançada, autenticação no backend e no mobile utilizando token JWT, criação de rotas utilizando o React Navigation, sistema de vendas online do backend ao aplicativo, testes unitários para todo o aplicativo utilizando o React Test Library, NodeJS usando o framework NestJS e acesso ao banco de dados utilizando TypeORM e Typescrip (TS)."
             
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={webdes}
              isBlog={false}
              title="Curso Web Moderno Completo com JavaScript + Projetos"
              description="Aplicações web modernas com HTML5, CSS3, Javascript e frameworks atuais. ECMAS S. 6, 7, 8, React, Vue, Bootstrap 4 e jQuery baseadas em componentes, projetos reais fullstack com bancos de dados NoSQL, MySQL, Postgres, MongoDB. Sistemas de autenticação com Node.js, builds com Gulp e Webpack, tecnologias web incluindo Angular 9"
             
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={jsguanabara}
              isBlog={false}
              title="Curso Grátis de JavaScript e ECMAScript para Iniciantes"
              description="Curso de linguagem JavaScript, voltado para iniciantes e para quem quiser aprender mais sobre ECMAScript, a versão padronizada do JS. Em um curso patrocinado pelo Google. Conheceito de JavaScript, comandos básicos do JavaScript, document, Object Model, condições em JavaScript, repetições (laços) em JavaScript e estudos avançados em JavaScript."
             
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={phpguanabara}
              isBlog={false}
              title="Curso Grátis de PHP básico para iniciantes"
              description="Curso de PHP para Iniciantes,  os primeiros passos para criar sites que utilizem a tecnologia PHP incorporada. Instalação de um servidor, criação de scripts básicos, interatividade com formulários e acesso a bancos de dados simples. Colocar seu site no ar utilizando um servidor profissional de hospedagem utilizando FTP."
             
            />
          </Col>
          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
